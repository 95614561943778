<template>
  <div>
    <master-component
      class="search-case"
      ref="masterComponent"
      :config="config"
      :showAdd="false"
      :showEdit="false"
    >
      <template v-slot:table-deptorName="{ item }">
        <router-link
          :to="`/case-details/${item.id}`"
          target="_blank"
          style="text-decoration: none;"
          class="teal--text"
        >{{ item.deptorName }}</router-link>
      </template>

      <template v-slot:table-custom-event-button="{ item }">
        <v-icon
          color="info"
          @click="seeDetails(item)"
        >launch</v-icon>
      </template>
    </master-component>

    <custom-dialog
      ref="kamnodWanFongDialog"
      :width="650"
      :title="titleKamnodWanFongDialog"
      @submit="submitWanFong"
    >
      <v-col>
        <master-render
          :rows="kamnodWanFongRows"
          v-model="kamnodWanFongModel"
        ></master-render>
      </v-col>
    </custom-dialog>

    <!-- <custom-dialog
      ref="duedateWanFongDialog"
      :width="650"
      :title="titleKamnodDuedateWanFongDialog"
      @submit="submitDuedateWanFong"
    >
      <v-col>
        <master-render
          :rows="duedateWanFongRows"
          v-model="kamnodDuedateWanFongModel"
        ></master-render>
      </v-col>
    </custom-dialog> -->
  </div>
</template>

<script>
import {
  InputType,
  StatusCase,
  UserRole,
  StatusPayment,
  Chalo,
  ActionSue,
} from "./../../../js/constants";

import CustomDialog from "./../../../components/custom/custom-dialog";
import { createDynamicModel } from "./../../../js/custom";
import {
  getStatusSue,
  getStatusCase,
  getCustomers,
  getBranches,
  getLots,
  getCourts,
  getLawyers,
} from "./../../../js/dropdown-service";

import { กำหนดวันที่ฟ้อง } from "./../update-status/config-sue-view";
import { momentToThaiFormat } from "./../../../js/date-helper";
import moment from "moment";

import { fakeAbbr, getFakeUrl } from "./../../../_helpers/fake-url";
import { Promise } from "q";
import { getToken } from "../../../js/authorization";

export default {
  components: {
    "custom-dialog": CustomDialog,
  },
  data() {
    return {
      config: {},
      selected: [],
      kamnodWanFongModel: {},
      kamnodDuedateWanFongModel: {},
      kamnodWanFongRows: กำหนดวันที่ฟ้อง(),
    };
  },
  created() {
    this.initialConfig();
  },
  methods: {
    initialConfig() {
      this.config = {
        name: "search-case",
        shortName: "Search case",
        search: this.searchSection,
        form: {},
        url: this.urlSection,
        table: this.tableSection,
        permission: {},
      };
    },
    seeDetails(item, index) {
      let routeData = this.$router.resolve({
        name: "case-details",
        params: {
          id: item.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    requestPlaintDate() {
      let url = `/case/requestPlaintDate`;
      // url = getFakeUrl(fakeAbbr.caseInfo.sendDataToLawyer) + url;

      let payload = {};
      payload.caseIds = this.selected.map((x) => x.id);

      this.$http.post(url, payload).then((res) => {
        this.showAlertSuccess();
      });
    },
    sendDataToLawyer() {
      let url = `/case/sendDataToLawyer`;
      // url = getFakeUrl(fakeAbbr.caseInfo.sendDataToLawyer) + url;

      let payload = {};
      payload.caseIds = this.selected.map((x) => x.id);

      this.$http.post(url, payload).then((res) => {
        this.showAlertSuccess();
      });
    },
    showKamnodWanFongDialog() {
      this.$refs.kamnodWanFongDialog.hideLoading();
      this.$refs.kamnodWanFongDialog.showDialog();

      this.kamnodWanFongModel = {};
      createDynamicModel(this, this.kamnodWanFongModel, this.kamnodWanFongRows);
    },
    // showDuedateWanfongDialog() {
    //   this.$refs.duedateWanFongDialog.hideLoading();
    //   this.$refs.duedateWanFongDialog.showDialog();

    //   this.kamnodDuedateWanFongModel = {};
    //   createDynamicModel(
    //     this,
    //     this.kamnodDuedateWanFongModel,
    //     this.duedateWanFongRows
    //   );
    // },
    submitWanFong() {
      let url = `/case/bulkKamnodWanFong`;

      let payload = {};
      payload.ids = this.selected.map((x) => x.id);
      Object.assign(payload, this.kamnodWanFongModel);

      this.$http.post(url, payload).then((res) => {
        this.showAlertSuccess();
        this.$refs.kamnodWanFongDialog.hideDialog();
      });
    },
    // submitDuedateWanFong() {
    //   let url = `/case/requestPlaintDate`;

    //   let payload = {};
    //   payload.ids = this.selected.map((x) => x.id);
    //   Object.assign(payload, this.kamnodDuedateWanFongModel);

    //   this.$http.post(url, payload).then((res) => {
    //     this.showAlertSuccess();
    //     this.$refs.duedateWanFongDialog.hideDialog();
    //   });
    // },
    hasSpecificField(model) {
      let valid = false;

      valid = [
        model.deptorName,
        model.idCardNumber,
        model.court,
        model.blackNumber,
        model.redNumber,
        model.lawyer,
      ].some((x) => x);
      return valid;
    },
  },
  computed: {
    duedateWanFongRows() {
      return [
        [
          {
            name: "date",
            text: "     วันที่",
            type: InputType.begin_end_date,
            default: {
              begin: "",
              end: "",
            },
            // columnClass: "col-sm-12 col-md-7"
          },
        ],
      ];
    },
    bulkAction() {
      // if (this.isServerProduction) {
      //   return [];
      // }

      return [
        {
          text: "ให้ทนายกำหนดวันฟ้อง",
          permission: [UserRole.ADMIN, UserRole.BACK_OFFICE],
          condition: ({ selected }) => {
            let result = selected.every(
              (x) => x.statusCase.id === StatusCase["ทำ Notice เสร็จแล้ว"].id
            );

            return result;
          },
          func: () => {
            this.selected = this.$refs.masterComponent.getSelectedItems();

            if (this.selected.length > 0) {
              // this.showDuedateWanfongDialog();
              this.requestPlaintDate();
            } else {
              this.showAlertError("กรุณาเลือกรายการที่ต้องการ");
            }
          },
        },
        {
          text: "ส่งคำฟ้องให้ทนาย",
          permission: [UserRole.ADMIN, UserRole.BACK_OFFICE],
          condition: ({ selected }) => {
            let result = selected.every(
              (x) => x.statusCase.id === StatusCase["ทำเอกสารฟ้องเสร็จแล้ว"].id
            );

            return result;
          },
          func: () => {
            this.selected = this.$refs.masterComponent.getSelectedItems();

            if (this.selected.length > 0) {
              this.sendDataToLawyer();
            } else {
              this.showAlertError("กรุณาเลือกรายการที่ต้องการ");
            }
          },
        },
        {
          text: "กำหนดวันฟ้อง",
          permission: [UserRole.LAWYER],
          condition: ({ selected }) => {
            let result = selected.every(
              (x) => x.statusCase.id === StatusCase["รอวันฟ้องจากทนาย"].id
            );

            return result;
          },
          func: () => {
            this.selected = this.$refs.masterComponent.getSelectedItems();

            if (this.selected.length > 0) {
              this.showKamnodWanFongDialog();
            } else {
              this.showAlertError("กรุณาเลือกรายการที่ต้องการ");
            }
          },
        },
      ];
    },
    titleKamnodWanFongDialog() {
      return `กำหนดวันฟ้อง ${this.selected.length} เคส`;
    },
    // titleKamnodDuedateWanFongDialog() {
    //   return `ส่งให้ทนายกำหนดวันฟ้อง ${this.selected.length} เคส`;
    // },
    urlSection() {
      let searchUrl = "/case/search";

      return {
        search: searchUrl,
      };
    },
    searchSection() {
      return {
        rows: [
          [
            {
              name: "deptorName",
              text: "ลูกหนี้",
              type: InputType.text,
              columnClass: "col-md-3",
            },
            {
              name: "idCardNumber",
              text: "เลขที่บัตรประชาชน",
              type: InputType.text,
              columnClass: "col-md-3",
            },
          ],
          [
            {
              name: "court",
              text: "ศาล",
              type: InputType.dropdown,
              data: {
                promise: getCourts(),
              },
              columnClass: "col-md-3",
            },
            {
              name: "blackNumber",
              text: "หมายเลขคดีดำ",
              type: InputType.text,
              columnClass: "col-md-3",
            },
            {
              name: "redNumber",
              text: "หมายเลขคดีแดง",
              type: InputType.text,
              columnClass: "col-md-3",
            },
            {
              name: "lawyer",
              text: "ทนาย",
              type: this.isLawyer ? InputType.offset : InputType.dropdown,
              data: {
                promise: this.isLawyer ? null : getLawyers(),
              },
              columnClass: "col-md-3",
            },
          ],
          [
            {
              type: InputType.section,
              columnClass: "mt-n12",
            },
          ],
          [
            {
              name: "customer",
              text: "ลูกค้า",
              type: InputType.dropdown,
              default: {
                id: "39b0a7f5-c033-44c6-8191-1fc570013048",
                text: "AEON",
              },
              data: {
                promise: getCustomers(),
              },
              rule: {
                required: true,
              },
              columnClass: "col-md-3",
              column: 0,
              showIf: (context) => {
                const model = context.model;
                let valid = this.hasSpecificField(model);
                if (!valid) {
                  return true;
                }

                return false;
              },
            },
            {
              name: "customer",
              text: "ลูกค้า",
              type: InputType.dropdown,
              default: {
                id: "39b0a7f5-c033-44c6-8191-1fc570013048",
                text: "AEON",
              },
              data: {
                promise: getCustomers(),
              },
              rule: {
                required: false,
              },
              columnClass: "col-md-3",
              column: 0,
              showIf: (context) => {
                const model = context.model;
                let valid = this.hasSpecificField(model);
                if (valid) {
                  return true;
                }

                return false;
              },
            },
            {
              name: "branch",
              text: "OA",
              type: InputType.dropdownRelate,
              parent: "customer",
              data: {
                url: `/dropdown/branches?authorize=true&includeCustomerText=false&customerId=`,
              },
              rule: {
                required: true,
              },
              columnClass: "col-md-3",
              column: 1,
              showIf: (context) => {
                const model = context.model;
                let valid = this.hasSpecificField(model);
                if (!valid) {
                  return true;
                }

                return false;
              },
            },
            {
              name: "branch",
              text: "OA",
              type: InputType.dropdownRelate,
              parent: "customer",
              data: {
                url: `/dropdown/branches?authorize=true&includeCustomerText=false&customerId=`,
              },
              rule: {
                required: false,
              },
              columnClass: "col-md-3",
              column: 1,
              showIf: (context) => {
                const model = context.model;
                let valid = this.hasSpecificField(model);
                if (valid) {
                  return true;
                }

                return false;
              },
            },
            // {
            //   name: "lotno",
            //   text: "Lot",
            //   type: InputType.dropdownRelate,
            //   parent: "customer",
            //   data: {
            //     url: "/dropdown/lots?customerId=",
            //   },
            //   columnClass: "col-md-3",
            // },
            {
              name: "lotno",
              text: "Lot",
              type: InputType.dropdownRelate,
              parent: "branch",
              data: {
                url: "/dropdown/lots?oaId=",
              },
              columnClass: "col-md-3",
            },
            {
              name: "ncbStatus",
              text: "NCB",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/NCBStatuses",
              },
            },
            // {
            //   type: InputType.offset,
            // },
          ],
          [
            {
              name: "statusCase",
              text: "สถานะเคส",
              type: InputType.dropdown,
              data: {
                promise: getStatusCase().then((res) => {
                  res.items.push(Chalo["ชะลอการฟ้อง"]);
                  res.items.push(Chalo["ชะลอ เพราะเข้า SAM"]);
                  res.items.push(StatusCase["ปิดบัญชี"]);
                  res.items.push(StatusCase["ผิดนัดทำยอม"]);
                  return res;
                }),
              },
              columnClass: "col-md-3",
            },
            {
              name: "statusSue",
              text: "สถานะคดี",
              type: InputType.dropdown,
              data: {
                promise: getStatusSue(),
              },
              columnClass: "col-md-3",
            },
            {
              name: "fongAppointmentDate",
              text: " วันพิจารณาคดี",
              type: InputType.begin_end_date,
              default: {
                begin: "",
                end: "",
              },
              columnClass: "col-sm-12 col-md-6",
              rule: {
                required: false,
              },
            },
            {
              type: InputType.offset,
            },
          ],
          [
            {
              name: "noPipaksa",
              text: "ไม่มีคำพิพากษา",
              type: InputType.checkBox,

              columnClass: "col-md-3",
              column: 1,
            },
          ],
          [
            {
              name: "morethan30day",
              text: "เกินกำหนด 30 วัน",
              type: InputType.checkBox,

              columnClass: "col-md-3",
              column: 1,
              column: 1,
              showIf: (context) => {
                const model = context.model;

                if (
                  model.statusSue?.id &&
                  model.statusSue.id.includes(ActionSue.พิพากษา)
                ) {
                  return true;
                }

                return false;
              },
            },
          ],

          [
            {
              name: "tamyomNCB",
              text: "ถึงกำหนดส่ง NCB",
              type: InputType.checkBox,
              columnClass: "col-md-3",
              column: 1,
              showIf: (context) => {
                const model = context.model;

                if (
                  model.statusSue?.id &&
                  model.statusSue.id.includes(ActionSue.ประนีประนอม)
                ) {
                  return true;
                }

                return false;
              },
            },
          ],
          [
            {
              name: "overDueDateFong",
              text: "เกินกำหนดฟ้อง",
              type: InputType.checkBox,
              columnClass: "col-md-3",
              column: 1,
              showIf: (context) => {
                const model = context.model;

                // if (model.statusSue?.id == ActionSue.ประนีประนอม) {
                //   return true;
                // }

                if (model.statusSue && model.statusSue.id) {
                  return false;
                }

                let arr = [
                  StatusCase["ระหว่างยื่นฟ้อง"].id,
                  StatusCase["แจ้งงดหมายตั้ง"].id,
                  StatusCase["รอถอนหมายตั้ง"].id,
                  StatusCase["แจ้งถอนฟ้อง"].id,
                  StatusCase["ปิดเคส"].id,
                  StatusCase["รอถอนหมายตั้ง"].id,
                  StatusCase["คืนงาน"].id,
                  StatusCase["ปิดบัญชี"].id,
                  StatusCase["ผิดนัดทำยอม"].id,
                  StatusCase["รอถอนฟ้อง"].id,
                  StatusCase["ปิดเคส"].reason["งดฟ้อง"].id,
                  StatusCase["ปิดเคส"].reason["ถอนฟ้อง"].id,
                  StatusCase["ปิดเคส"].reason["ออกหมายตั้ง"].id,
                  StatusCase["ปิดเคส"].reason["งดหมายตั้ง"].id,
                  StatusCase["ปิดเคส"].reason["ปิดเคสทำยอม"].id,
                  StatusCase["เข้าSAMก่อนฟ้อง"].id,
                  Chalo["ชะลอการฟ้อง"].id,
                  Chalo["ชะลอ เพราะเข้า SAM"].id,
                  StatusCase["ปิดบัญชี"].id,
                ];
                if (
                  model.statusCase &&
                  model.statusCase.id &&
                  arr.includes(model.statusCase.id)
                ) {
                  return false;
                }

                return true;
              },
            },
          ],
          [
            {
              name: "closeAccountOver30Day",
              text: "ปิดบัญชีเกิน 30 วัน",
              type: InputType.checkBox,
              columnClass: "col-md-3",
              column: 1,
              showIf: (context) => {
                const model = context.model;

                if (
                  model.statusCase &&
                  model.statusCase.id == StatusCase.ปิดบัญชี.id
                ) {
                  return true;
                }

                return false;
              },
            },
          ],
          [
            {
              name: "pidnudTamyom_sentMai",
              text: "ถึงกำหนดส่งหมาย",
              type: InputType.checkBox,
              columnClass: "col-md-3",
              column: 1,
              showIf: (context) => {
                const model = context.model;

                if (
                  model.statusCase &&
                  model.statusCase.id == StatusCase.ผิดนัดทำยอม.id
                ) {
                  return true;
                }

                return false;
              },
            },
          ],
          [
            {
              name: "namkam",
              text: "ตามงาน",
              type: InputType.dropdown,
              data: {
                promise: Promise.resolve({
                  items: [
                    { id: "namkamOokMai", text: "ถึงกำหนดออกหมาย" },
                    { id: "namkamSentMai", text: "ถึงกำหนดส่งหมาย" },
                    { id: "namkamNCB", text: "ถึงกำหนดส่ง NCB" },
                  ],
                }),
              },
              showIf: (context) => {
                const model = context.model;

                if (
                  model.statusSue?.id &&
                  model.statusSue.id.includes(ActionSue.นำคำบังคับ)
                ) {
                  return true;
                }

                return false;
              },
              columnClass: "col-md-3",
            },
          ],
          // [
          //   {
          //     name: "namkamOokMai",
          //     text: "ถึงกำหนดออกหมาย",
          //     type: InputType.checkBox,

          //     columnClass: "col-md-3",
          //     column: 1,
          //     showIf: (context) => {
          //       const model = context.model;

          //       if (
          //         model.statusSue?.id &&
          //         model.statusSue.id.includes(ActionSue.นำคำบังคับ)
          //       ) {
          //         return true;
          //       }

          //       return false;
          //     },
          //   },
          // ],
          // [
          //   {
          //     name: "namkamSentMai",
          //     text: "ถึงกำหนดส่งหมาย",
          //     type: InputType.checkBox,

          //     columnClass: "col-md-3",
          //     column: 1,
          //     showIf: (context) => {
          //       const model = context.model;

          //       if (
          //         model.statusSue?.id &&
          //         model.statusSue.id.includes(ActionSue.นำคำบังคับ)
          //       ) {
          //         return true;
          //       }

          //       return false;
          //     },
          //   },
          // ],

          // [
          //   {
          //     name: "namkamNCB",
          //     text: "ถึงกำหนดส่ง NCB",
          //     type: InputType.checkBox,

          //     columnClass: "col-md-3",
          //     column: 1,
          //     showIf: (context) => {
          //       const model = context.model;

          //       if (
          //         model.statusSue?.id &&
          //         model.statusSue.id.includes(ActionSue.นำคำบังคับ)
          //       ) {
          //         return true;
          //       }

          //       return false;
          //     },
          //   },
          // ],
        ],
      };
    },

    tableSection() {
      return {
        bulkAction: this.bulkAction,
        columns: [
          {
            name: "customer.text",
            text: "ลูกค้า",
            style: {
              width: "48px",
            },
          },
          {
            name: "deptorName",
            text: "ลูกหนี้",
            class: "teal--text",
            style: {
              width: "140px",
            },
          },
          {
            name: "idCardNumber",
            text: "เลขบัตรประชาชน",
            style: {
              width: "110px",
            },
          },
          {
            name: "court.text",
            text: "ศาล",
            style: {
              width: "120px",
            },
          },
          {
            name: "lawyer.text",
            text: "ทนาย",
            style: {
              width: "120px",
            },
          },

          {
            name: "lotNumber",
            text: "Lot",
            style: {
              width: "40px",
            },
          },

          {
            name: "plaintDate",
            text: "วันฟ้อง",
            style: {
              width: "83px",
            },
          },
          {
            name: "grandTotal",
            text: "จำนวนเงิน",
            numeric: 2,
            class: "text-right",
            style: {
              width: "80px",
            },
          },
          {
            name: "blackNumber",
            text: "เลขคดีดำ",
            style: {
              width: "80px",
            },
          },
          {
            name: "statusCase.text",
            text: "สถานะเคส",
            style: {
              "white-space": "nowrap",
            },
            classFunc: (item) => {
              let stCaseId = item.statusCase ? item.statusCase.id : "";

              switch (stCaseId) {
                case StatusCase["ปิดเคส"].id:
                  return "success--text";
                case StatusCase["New"].id:
                  return "red--text";
                default:
                  return "";
              }
            },
          },
          {
            name: "statusSue.text",
            text: "สถานะคดี",
            style: {
              "white-space": "nowrap",
            },
          },
        ],
      };
    },
  },
};
</script>

<style>
.search-case .master-table table th,
.search-case .master-table table td {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.search-case .master-table table td.col-action {
  text-align: center;
}
</style>