import { InputType, StatusSue, StatusCase, PipaksaResultType, ActionSue } from '../../../js/constants';
import { getReasonSueToLuen } from './../../../js/dropdown-service';



export function getUI({ plaintDate, option }) {
    switch (option.id) {
        case ActionSue['กำหนดวันฟ้อง']:
            {
                return กำหนดวันที่ฟ้อง()
            }
        case ActionSue['เลื่อนก่อนฟ้อง']:
            {
                return เลื่อน()
            }
        case ActionSue['ฟ้อง']:
            {
                return ฟ้อง(plaintDate)
            }
        case ActionSue['เลื่อนหลังฟ้อง']:
            {
                return เลื่อนหลังจากได้เลขคดีดำ()
            }
        case ActionSue['พิพากษา']:
            {
                return พิพากษาคดีแดง()
            }
        case ActionSue['ประนีประนอม']:
            {
                return ประนีประนอมยอมความ()
            }

        case ActionSue['นำคำบังคับ']:
            {
                return นำคำบังคับ()
            }
        // case ActionSue['กำหนดวันงดหมายตั้ง']:
        //     {
        //         return กำหนดวันงดหมายตั้ง()
        //     }
        case ActionSue['ออกหมายตั้งเจ้าหน้าที่บังคับคดี']:
        case ActionSue['สัญญายอม_ออกหมายตั้งเจ้าหน้าที่บังคับคดี']:
            {
                return ออกหมายตั้งเจ้าหน้าที่บังคับคดี()
            }
        case ActionSue['พิพากษา_กำหนดวันงดหมายตั้ง']:
        case ActionSue['สัญญายอม_กำหนดวันงดหมายตั้ง']:
        case ActionSue['นำคำบังคับ_กำหนดวันงดหมายตั้ง']:
            {
                return กำหนดวันงดหมายตั้ง()
            }
        case ActionSue['งดหมายตั้ง']:
            {
                return งดหมายตั้ง()
            }
        case ActionSue['แจ้งถอนฟ้อง']:
            {
                return กำหนดวันถอนฟ้อง()
            }
        case ActionSue['ถอนฟ้อง']:
            {
                return ถอนฟ้อง()
            }
        default: return Promise.resolve({ items: [] })
    }
}


export function กำหนดวันที่ฟ้อง() {
    return [
        [
            {
                name: "actionDate",
                text: "วันฟ้อง",
                type: InputType.datePicker,
                rule: {
                    required: true
                }
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            },
        ]
    ]
}


function กำหนดวันถอนฟ้อง() {
    return [
        [
            {
                name: "actionDate",
                text: "วันที่แจ้งถอนฟ้อง",
                type: InputType.datePicker,
                rule: {
                    required: true
                },
                columnClass: 'col-md-4'

            },
            {
                name: "reasonTonFong",
                text: "เหตุผล",
                type: InputType.dropdown,
                rule: {
                    required: true
                },
                data: {
                    url: '/dropdown/reasonToTonFong'
                },
                columnClass: 'col-md-8'
            },
            {
                name: "reasonTonFongOther",
                text: "เหตุผลอื่นๆ",
                type: InputType.textArea,
                columnClass: 'col-md-6'
            },
        ]
    ]
}

function กำหนดวันงดหมายตั้ง() {
    return [
        [
            {
                name: "actionDate",
                text: "วันที่แจ้งงดหมายตั้ง",
                type: InputType.datePicker,
                rule: {
                    required: true
                },
                columnClass: 'col-md-4'

            },
            {
                name: "reasonNgodMaiTang",
                text: "เหตุผล",
                type: InputType.dropdown,
                rule: {
                    required: true
                },
                data: {
                    url: '/dropdown/ReasonNgodMaiTang'
                },
                columnClass: 'col-md-8'
            },
            {
                name: "reasonNgodMaiTangOther",
                text: "เหตุผลอื่นๆ",
                type: InputType.textArea,
                columnClass: 'col-md-6'
            },
        ]
    ]
}


function ฟ้อง(plaintDate) {
    return [
        [
            {
                name: "completedDocument",
                text: "เอกสารครบแล้ว",
                type: InputType.checkBox
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            }
        ],
        [
            {
                name: "actionDate",
                text: "วันที่ฟ้อง",
                type: InputType.datePicker,
                default: plaintDate
            },
            {
                name: "appointmentDate",
                text: "กำหนดวันนัดพิจารณาคดี",
                type: InputType.datePicker
            },
            {
                name: "time",
                text: "เวลา กำหนดวันนัดพิจารณาคดี",
                default: "09:00",
                type: InputType.timePicker
            },
            {
                type: InputType.offset
            },

        ],
        [
            {
                name: "caseNumberPrefix",
                text: "หมายเลขคดีดำ",
                type: InputType.dropdown,
                data: {
                    url: '/dropdown/LawyerAction/CaseNumberPrefixs'
                },
                inputClass: "case-number-prefix",
                column: 0,

            },
            {
                name: "undecidedCaseNumber",
                text: "",
                type: InputType.text,
                inputClass: 'sue-number',
                column: 0,
                rule: {
                    regex: /^(?!(0))\d{1,5}\/(256[0-9]|257[0-2])$/,
                }
            },
            {
                name: "witnessExamAppointmentDate",
                text: "วันนัดไกล่เกลี่ย",
                type: InputType.datePicker
            },
            {
                name: "witnessExamAppointmentTime",
                text: "เวลา นัดไกล่เกลี่ย",
                default: "09:00",
                type: InputType.timePicker
            },
            {
                type: InputType.offset
            },

        ]
    ]
}

function เลื่อน() {
    return [
        [
            {
                name: "actionDate",
                text: "วันที่เกิดการเลื่อน",
                type: InputType.datePicker,
                rule: {
                    required: true
                }
            },
            {
                name: "appointmentDate",
                text: "วัน นัดครั้งถัดไป",
                type: InputType.datePicker
            },
            {
                name: "concilateTime",
                text: "เวลา นัดครั้งถัดไป",
                default: "09:00",
                type: InputType.timePicker
            },
            {
                type: InputType.offset
            },
        ],
        [
            {
                name: "reason",
                text: "เหตุผล",
                type: InputType.textArea,
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            },
        ]
    ]
}

function เลื่อนหลังจากได้เลขคดีดำ() {
    return [
        [
            {
                name: "actionDate",
                text: "วันที่เกิดการเลื่อน",
                type: InputType.datePicker,
                rule: {
                    required: true
                }
            },
            {
                name: "appointmentDate",
                text: "วัน นัดครั้งถัดไป",
                type: InputType.datePicker
            },
            {
                name: "concilateTime",
                text: "เวลา นัดครั้งถัดไป",
                default: "09:00",
                type: InputType.timePicker
            },
            {
                type: InputType.offset
            },
            // {
            //     name: "songKooKwamCost",
            //     text: "ค่าส่งคำคู่ความ (ค่านำหมายใหม่)",
            //     type: InputType.number,
            //     decimal: 2,
            // }
        ],
        [
            {
                name: "reason",
                text: "เหตุผล",
                type: InputType.textArea,
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            }
        ]
    ]
}



function พิพากษาคดีแดง() {
    return [
        [
            {
                name: "actionDate",
                text: "วันที่พิพากษา",
                type: InputType.datePicker,
                rule: {
                    required: true
                }
            },
            {
                name: "caseNumberPrefix",
                text: "หมายเลขคดีแดง",
                type: InputType.dropdown,
                data: {
                    url: '/dropdown/LawyerAction/CaseNumberPrefixs'
                },
                inputClass: "case-number-prefix",
                column: 1,
            },
            {
                name: "decidedCaseNumber",
                text: "",
                type: InputType.text,
                inputClass: 'sue-number',
                column: 1,
                rule: {
                    regex: /^(?!(0))\d{1,5}\/(256[0-9]|257[0-2])$/,
                }
            },
            {
                type: InputType.offset
            },
        ],
        [
            {
                type: InputType.section,
                text: "ผลการพิพากษา",
                columnClass: "pb-5 white--text"
            }
        ],
        [
            {
                name: "lawyerFee",
                text: "ค่าทนายความ",
                type: InputType.number,
                decimal: 2,
            },
            {
                name: "litigationCosts",
                text: "ค่าใช้จ่ายในการดำเนินคดี",
                type: InputType.number,
                decimal: 2,
            },
            {
                type: InputType.offset
            }
        ],
        [
            {
                name: "resultPiPakSa",
                text: "คำพิพากษา",
                type: InputType.textArea,
            }
        ],
        [
            {
                name: "judgmentResults",
                type: InputType.pipaksaList,
                default: {
                    summary: {

                    },
                    items: []
                },
            }
        ],
    ]
}

function นำคำบังคับ() {
    return [
        [
            {
                name: "completedDocument",
                text: "เอกสารครบแล้ว",
                type: InputType.checkBox,

            },
            {
                name: "defendantComed",
                text: "จำเลยมาศาล (ไม่ต้องนำคำ)",
                type: InputType.checkBox,
                columnClass: 'col-md-4'
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            },
        ],
        [
            {
                name: "namKamItems",
                type: InputType.namkamList,
                default: []
            }
        ],
        // [
        //     {
        //         name: "actionDate",
        //         text: "วันที่ 2",
        //         type: InputType.datePicker,
        //         rule: {
        //             required: true
        //         }
        //     },
        //     {
        //         name: "songKooKwamCost",
        //         text: "ค่าส่งคำคู่ความ (ค่านำคำบังคับ)",
        //         type: InputType.number,
        //         decimal: 2,

        //     },
        //     {
        //         name: "tik",
        //         text: "ประกาศสื่อ",
        //         type: InputType.checkBox
        //     },
        //     {
        //         type: InputType.offset
        //     }
        // ],
    ]
}

function ออกหมายตั้งเจ้าหน้าที่บังคับคดี() {
    return [
        [
            {
                name: "completedDocument",
                text: "เอกสารครบแล้ว",
                type: InputType.checkBox
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            }
        ],
        [
            {
                name: "actionDate",
                text: "วันที่",
                type: InputType.datePicker,
                rule: {
                    required: true
                }
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            },
        ]
    ]
}


function ประนีประนอมยอมความ() {
    return [
        [
            {
                name: "completedDocument",
                text: "เอกสารครบแล้ว",
                type: InputType.checkBox
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            }
        ],
        [
            {
                name: "actionDate",
                text: "วันที่พิพากษา",
                type: InputType.datePicker,
                rule: {
                    required: true
                }
            },
            {
                name: "caseNumberPrefix",
                text: "หมายเลขคดีแดง",
                type: InputType.dropdown,
                data: {
                    url: '/dropdown/LawyerAction/CaseNumberPrefixs'
                },
                inputClass: "case-number-prefix",
                column: 1,
            },
            {
                name: "decidedCaseNumber",
                text: "",
                type: InputType.text,
                inputClass: 'sue-number',
                column: 1,
                rule: {
                    regex: /^(?!(0))\d{1,5}\/(256[0-9]|257[0-2])$/,
                }
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            },
        ],
        [
            {
                name: "total",
                text: "ยอดเงินตามคำพิพากษา",
                type: InputType.number,
                decimal: 2,
            },
            {
                name: "installment",
                text: "งวดละ",
                type: InputType.number,
                decimal: 2,
            },
            {
                name: "installmentNumber",
                text: "จำนวนงวด",
                type: InputType.number,
                decimal: 0,
            },
            {
                name: "lawyerFee",
                text: "ค่าทนาย",
                type: InputType.number,
                decimal: 2,
            },
            {
                name: "telDeptor",
                text: "เบอร์โทรลูกหนี้",
                type: InputType.text,
            },
        ],
        [
            {
                name: "feeRefund",
                text: "ค่าธรรมเนียมโอนคืน",
                type: InputType.number,
                decimal: 2,
            },
            {
                name: "refundDate",
                text: "วันที่โอนคืน",
                type: InputType.datePicker,
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            }
        ],
        [
            {
                name: "sanyayom",
                text: "สัญญายอม",
                type: InputType.textArea,
            }
        ],
        [
            {
                name: "judgmentResults",
                type: InputType.pipaksaList,
                default: {
                    summary: {

                    },
                    items: []
                },
            }
        ],
    ]
}

function ถอนฟ้อง() {
    return [
        [
            {
                name: "actionDate",
                text: "วันที่ถอนฟ้อง",
                type: InputType.datePicker,
                rule: {
                    required: true
                }
            },
            {
                name: "caseNumberPrefix",
                text: "หมายเลขคดีแดง",
                type: InputType.dropdown,
                data: {
                    url: '/dropdown/LawyerAction/CaseNumberPrefixs'
                },
                inputClass: "case-number-prefix",
                column: 1,
            },
            {
                name: "decidedCaseNumber",
                text: "",
                type: InputType.text,
                inputClass: 'sue-number',
                column: 1,
                rule: {
                    regex: /^(?!(0))\d{1,5}\/(256[0-9]|257[0-2])$/,
                }
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            }

        ],
        [
            {
                name: "feeRefund",
                text: "ค่าธรรมเนียมโอนคืน",
                type: InputType.number,
                decimal: 2,
            },
            {
                name: "refundDate",
                text: "วันที่โอนคืน",
                type: InputType.datePicker
            },
            {
                type: InputType.offset
            }
            ,
            {
                type: InputType.offset
            }
        ]
    ]
}

function งดหมายตั้ง() {
    return [
        [
            {
                name: "actionDate",
                text: "วันที่",
                type: InputType.datePicker,
                rule: {
                    required: true
                }
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            }
        ]
    ]
}